/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  FormHelperText,
  LinearProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { CustomButton, Wrapper, CustomInput } from "../../components";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppState } from "./state";
import { userRegister, userUpdate } from "../../slice/user.slice";
import { showToast } from "../../utils";
import { FormattedMessage, useIntl } from "react-intl";
import { LanguageContext } from "../../context/languageContext";

const validationSchema = Yup.object().shape({
  Notes: Yup.string().required("Notes is required"),
  agree: Yup.boolean().oneOf([true], "Please agree to terms and conditions"),
});

const AdditionalInformation = ({ onPrev }) => {
  const { locale, selectLanguage } = useContext(LanguageContext);
  const [toast, setToast] = useState(false);
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const [state, setState] = useAppState();
  const [loading, setLoading] = useState(false);
  const userSelector = useSelector((state) => state.user);
  const userData = userSelector?.user;
  const userFormData = userSelector?.userFormData
    ? userSelector?.userFormData
    : "";
  let updateMessage = useIntl().formatMessage({ id: "yourRequestTaken" });
  const progress = userSelector?.progress;
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: { ...state, agree: false },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    if (userFormData?.Notes && !state.Notes) {
      reset({
        Notes: userFormData?.Notes,
      });
    }
  }, [userFormData]);

  const onSubmit = (data, name) => {
    setLoading(true);
    if (name === "update") {
      const payload = { ...userFormData, ...state };
      dispatch(userUpdate(payload, userFormData))
        .then((res) => {
          setToast({
            type: "success",
            message: updateMessage,
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log("err:", err);
          setLoading(false);
          setToast({
            type: "error",
            message: "Something went wrong. Please try again later.",
          });
        });
    } else {
      setState({ ...state, ...data });
      if (userFormData.AffiliateCode) {
        const payload = {
          ...state,
          ...data,
          ID: userData?.sharepoint_reference_id,
        };
        delete payload?.agree;
        dispatch(userUpdate(payload))
          .then((res) => {
            setToast({
              type: "success",
              message: updateMessage,
            });
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      } else {
        dispatch(userRegister(state))
          .then((res) => {
            setToast({
              type: "success",
              message: updateMessage,
            });
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
          });
      }
    }
  };

  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const agree = watch("agree");
  return (
    // Additional Information
    <>
      <Snackbar open={loading}>
        <Alert style={{ width: "300px" }} severity="info">
          <Box style={{ display: "flex", alignItems: "center" }}>
            <LinearProgress
              sx={{ width: "200px" }}
              variant="determinate"
              value={progress}
            />
            <Typography
              color={
                locale === "en"
                  ? palette.secondary[700]
                  : palette.secondary[400]
              }
            >{`${Math.round(progress)}%`}</Typography>
          </Box>
        </Alert>
      </Snackbar>
      {toast?.type && showToast(toast?.type, toast?.message, setToast)}
      <Box
        dir={locale === "ar" ? "rtl" : "ltr"}
        sx={{
          "&[dir|='rtl']": {
            "& .note-message label": {
              transform: "translateX(6%) scale(0.90)",
            },
          },
        }}
      >
        {userFormData.Status === "In Progress" && (
          <Wrapper>
            {" "}
            <FormHelperText
              sx={{
                fontSize: "20px",
                paddingBottom: "10px",
              }}
            >
              <strong>
                <FormattedMessage id="waitingAprroval" />
              </strong>
            </FormHelperText>
          </Wrapper>
        )}
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Wrapper>
            <Box
              sx={
                userFormData.Status === "In Progress"
                  ? {
                      opacity: [0.9, 0.8, 0.7],
                      pointerEvents: "none",
                    }
                  : {}
              }
            >
              <Grid
                container
                sx={{
                  "& .MuiGrid-container": {
                    marginBottom: "0 !important",
                    "& .MuiGrid-root": {
                      marginBottom: "20px",
                    },
                  },
                  "& .MuiGrid-root": {
                    marginBottom: "20px",
                  },
                }}
              >
                <Grid item lg={12} sm={12} xs={12}>
                  <CustomInput
                    label={useIntl().formatMessage({ id: "note" })}
                    type="text"
                    placeholder={useIntl().formatMessage({ id: "enterNotes" })}
                    name="Notes"
                    rows={5}
                    error={errors?.Notes?.message}
                    control={control}
                  />

                  <Box
                    component="div"
                    className="form-group note-message"
                    padding="20px 0 40px"
                    sx={{
                      "& .MuiFormControlLabel-root": {
                        alignItems: "flex-start",
                      },
                    }}
                  >
                    <Controller
                      name="agree"
                      control={control}
                      rules={{
                        required: "Please accept the terms and conditions",
                      }}
                      render={({ value, field: { onChange } }) => (
                        <FormControlLabel
                          name="agree"
                          onChange={onChange}
                          control={
                            <Checkbox
                              checked={agree}
                              onChange={onChange}
                              defaultChecked={false}
                            />
                          }
                          label={
                            <Box>
                              <FormattedMessage id="noteInformationProvided1" />
                              <Typography marginTop="15px">
                                {" "}
                                <FormattedMessage id="noteInformationProvided2" />
                              </Typography>
                            </Box>
                          }
                        />
                      )}
                    />
                    {errors?.agree?.message && (
                      <Typography color={palette.error.main}>
                        {errors?.agree?.message}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Wrapper>
          <Box component="div">
            <Box className="dashboard-footer">
              <Box className="footer-item">
                <Typography
                  color={
                    locale === "en"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => selectLanguage("en")}
                >
                  <FormattedMessage id="english" />
                </Typography>
                <Typography
                  color={
                    locale === "fr"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => selectLanguage("fr")}
                >
                  <FormattedMessage id="french" />
                </Typography>
                <Typography
                  color={
                    locale === "ar"
                      ? palette.secondary[700]
                      : palette.secondary[400]
                  }
                  onClick={() => selectLanguage("ar")}
                >
                  <FormattedMessage id="arabic" />
                </Typography>
              </Box>
              <Box display="flex" columnGap="10px">
                <CustomButton
                  title={useIntl().formatMessage({ id: "back" })}
                  outline
                  size="small"
                  disable={loading}
                  onClick={onPrev}
                />
                {!userFormData && (
                  <CustomButton
                    title={useIntl().formatMessage({ id: "finish" })}
                    size="small"
                    type="submit"
                    disable={loading}
                    onClick={
                      !loading &&
                      handleSubmit((data) => onSubmit(data, "finish"))
                    }
                    loading={loading}
                  />
                )}
                {userFormData && userFormData.Status !== "In Progress" && (
                  <CustomButton
                    title={useIntl().formatMessage({ id: "update" })}
                    type="submit"
                    size="small"
                    variant="contained"
                    disable={loading}
                    loading={loading}
                    onClick={
                      !loading &&
                      handleSubmit((data) => onSubmit(data, "update"))
                    }
                  />
                )}
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AdditionalInformation;
